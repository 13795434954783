@import "common";
/*====Common here===*/
.display_block{
  display: block;
}
.display_none{
  display: none;
}

body{
  overflow-x: hidden;
}

/*====Common here===*/


/*====Globle css here===*/


.MuiContainer-root {
  max-width:100vw!important; 
}
.download-upload-icon {
  font-size: 40px!important;
}

p,
span {
  font-size: 14px !important;
}
h6 {
  font-size: 16px !important;
}

.cursor-pointer {
  cursor:pointer;
}

.no_data_text_align_center {
  td:last-child div:last-child {
    width: auto !important;
  }
}

/*====Globle css here===*/

/*=====All tables css starts here=====*/
.tss-1x5mjc5-MUIDataTable-root .datatables-noprint {
  text-align: center;
  width: 100%;
}
.text_right {
  text-align:right!important;
}

.first_table_cell_small_width{
  
  table td:first-child div {
      width: 50px !important;
  }
  table th:first-child div {
      width: 50px !important;
  }

 table td:last-child div{
  width:auto!important;
  min-width:70px;
  }

  table th:last-child div{
    width:auto!important;
    min-width:70px;
  }
}

.shipment_product_table_box{
  width:300px!important;
}

.shipment_product_table {             
  thead {
    background-color: #f0f2f2;
    tr > th {
      width:150px;
    }
  }  
    th {
      width:150px;
       word-wrap: break-word;
    }
    td{
      width:150px;
      word-wrap: break-word;
    }

    td > div{
      width:150px;
      word-wrap: break-word;
    }
}
.table_head_bg_color{
  background-color: #f0f2f2;
}


.minimum_column {
  .text_right{
    text-align:right;
  }
  td > div{
    width:200px;
    word-wrap: break-word;
  }
  th > div{
    width:200px;
    word-wrap: break-word;
  }
}


.report_table{
  margin:50px 0px;
  .text_right{
    text-align:right;
  }
  th > div {
    text-align:center;
    // padding: 5px 0px;
    width: 204px;
    word-wrap: break-word;
  }
  td > div {
    width: 204px;
    padding: 5px 0px;
    word-wrap: break-word;
  }
}

.payment_table{
  margin:50px 0px;
  .text_right{
    text-align:right;
  }
  th > div {
     width: 250px;
    word-wrap: break-word;
  }
  td >div   {
    width: 250px;
    word-wrap: break-word;
  }
  td > div:last-child{
    width:auto;
  }
}


.common_table {
  margin:50px 0px;
  .text_right{
    text-align:right;
  }
  th > div {
    width: 130px;
  }
  th {
    width: 130px;
    word-wrap: break-word;
  }
  td > div {
    width: 130px;
    word-wrap: break-word;
  }
  td > div:last-child{
    width:auto;
  }
}


/*=====All tables css starts here=====*/


/*====Bulk Product Upload here===*/
.primary {
  color: $primary;
}
.bg-white {
  background-color: $bg-white;
}
.profile-bg {
  background-color: $profile-bg;
}
.bg-gray {
  background: $bg-gray;
}
.float_right {
  float: right;
}
.btn_custom_inline {
  margin-left: 6px !important;
  height: 55px !important;
}
p,
span {
  font-size: 14px !important;
}
.name_menu {
  padding: 10px 14px;
}
.mt_300 {
  margin-top: 200px;
}
.bg-light-gray {
  background: #f1f3f5;
}
.menu_margin {
  margin-top: 48px;
}
.menu_width {
  width: 220px;
}
.main {
  min-height: 100%;
}
.maintanance_page{   
  max-width:1200px;
  margin:100px auto;
  img{
    max-width:200px
  }
  .under_maintanance{
    padding-top:100px;
    h1{
      font-size:96px;
      font-weight:bold;
    }
    h3{
      font-size:36px;
    }
  }
  .under_img{
    max-width:600px;
    // width:100%;
  }
}
.server_maintinence_onff{
  display:inline!important;
}
.footer {
  text-align: center;
  margin-top: 50px;
  background-color: #f9f9f9 !important;
  height: 50px !important;
  line-height: 50px !important;
  bottom: 0px;
  width: 100%;
}
.menu_item {
  max-width: 200px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.text_center {
  text-align: center;
}

.shipcard {
   border:1px solid #ddd;
   padding:25px;
 }
.radio_button_box{
  width:202px;
}

.sale-list-drop-down-padding{
  margin-top: 83px !important;
}

.text-danger {
  color: $danger;
}

.template-border-text {
  @include flexbox;
}
.template-download-border {
  @include flexbox;
  color: $primary;
  border-left: transparent;
  cursor: pointer;
}

.file-border {
  display: block;
  border: 2px solid #ddd;
  border-style: dashed;
  text-align: center;
  padding: 3rem 1rem;
  .upload-btn {
    display: block;
    margin: 20px auto;
    padding: 5px 20px;
  }
}

.logo-img {
  display: flex;
  margin: 100px auto;
  width: 220px;
}

.logo-content {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.space-between {
  background: $bg-gray;
  display: flex;
  justify-content: space-between;
}

.forgot-text {
  color: #a7a7a7;
  cursor: pointer;
  display: inline-block;
  float: right;
}

.center-model {
  @include model;
  max-width:400px;
  text-align:center;
}
.center-model-information{
  @include model;
  max-height: 60%;
  overflow: auto;
  outline: none;
  border: none;
}

/*====Bulk Product Upload ends here===*/

//CAncellation css

// .CZ-Cancellation-Error {
//   display: flex;
//   margin-right: 10px;
// }

.paymentReport {
  padding-left: 2;
  padding-right: 2;

  .date-picker-default {
    background-color: white;
    margin-right: 10px !important;
  }

  .PaymentReport-datepicker {
    display: flex !important;
    align-items: baseline;
    margin-top: 8px !important;
  }

  @media screen and (max-width: 880px) {
    .paymentReports-product-table {
      display: block !important;
      overflow-x: scroll;
    }
  }

  .paymentReport-dropdown {
    height: 55;
    align-items: center;
    background-color: #ffffff;
  }
  
  .paymentReport-table-header {
    margin-top: 1;
    background-color: #ffffff;
  }
}
  .pr_search_bar{
    display: flex;
    align-items: center;
  }
  
  .pr_download_btn{
    @include centerFlex;
    align-items: center;
    margin-left: 20px;
  }
.sales_list_header{
  display:flex;
  // justify-content:center;
  align-items: center;
}

  .sales_list_header{
    @media screen and (min-width: 1200px) {
       @include centerFlex;
    }
        align-items: center;
  }
.pr-table {
  display: block;
  overflow-x: auto;
}
.Order_id_link{
  color:rgb(0, 104, 77);
  text-decoration: none;
    &:hover {
    text-decoration: underline;
   }
   cursor: pointer;
}
.reset_btn{
  display: flex;
  justify-content: start;
}
.submit_btn{
  @media screen and (min-width: 1200px) {
    @include centerFlex;
  }
  @media screen and (max-width: 1199px) {
    padding-right: 100px;
  }
}

.pr_download_btn{
  padding: 28px !important;
  margin-top: 21px !important;
}
.sales_table_negative_quantity{
  color: red !important;
}
.sr_history_filter{
  margin-right: 40px !important;
  margin-bottom: 20px !important;
}
.margin_download_button{
  margin-bottom: 27px !important;
}

/*====Responsive Table===*/
#input_right {
  text-align: right!important;
}
.text_right {
  text-align: right!important;
}
.table_btn {
  display: flex !important;
  padding: 5px 10px !important;
  text-transform: capitalize !important;
  margin-right: 4px !important;
}

.unregistered-images-fixed-table-width .seller-no-column {
  width: 50px;
  max-width: 50px;
  min-width: 50px;
}

.unregistered-images-fixed-table-width .seller-code-column {
  width: 130px;
  max-width: 130px;
  min-width: 130px;
}
.unregistered-images-fixed-table-width .seller-name-column {
  width: 200px;
  max-width: 200px;
  min-width: 200px;
}
.unregistered-images-fixed-table-width .cpc-column {
  width: 170px;
  max-width: 170px;
  min-width: 170px;
}
.unregistered-images-fixed-table-width .product-name-column {
  width: 300px;
  max-width: 300px;
  min-width: 300px;
}
.unregistered-images-fixed-table-width .flag-column {
  width: 105px;
  max-width: 105px;
  min-width: 105px;
}

.break-word-column {
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
}

.fixed-table .MuiTableCell-root:nth-child(1) {
  width: 50px;
  max-width: 50px;
  min-width: 50px;
}

.fixed-table .MuiTableCell-root:nth-child(2),
.fixed-table .MuiTableCell-root:nth-child(5),
.fixed-table .MuiTableCell-root:nth-child(6) {
  width: 130px;
  max-width: 130px;
  min-width: 130px;
}

.fixed-table .MuiTableCell-root:nth-child(3),
.fixed-table .MuiTableCell-root:nth-child(4) {
  width: 300px;
  max-width: 300px;
  min-width: 300px;
}

.responsive_table table {
  // display: table;
  // overflow-x: auto;
  // white-space: nowrap;
  // width: 100%;
}
.responsive_table tbody tr {
  text-align: center !important;
  width:300px;
  word-wrap: break-word;
}
.responsive_table td {
  text-align: center !important;
  width:300px;
}
.responsive_table td > div{
  text-align: center !important;
  width:300px;
  word-wrap: break-word;
}
.responsive_table th > div {
  width:300px;
  text-align: center !important;
  font-weight: 600;
  word-wrap: break-word;
}


.store_table {
  td > div{
  width:200px;
  word-wrap: break-word;
}
th  {
  width:200px;
  // font-weight: 600;
  word-wrap: break-word;
}
 th > div {
  width:200px;
  // font-weight: 600;
  word-wrap: break-word;
}
}



/*====Responsive Table===*/

/*====Responsive media===*/

@media screen and (max-width: 600px) {
  .logo-img {
    max-width: 200px;
    height: auto;
    margin: 50px auto;
  }
  .center-model-information {
    width: 90% !important;
  }
}

/*====Responsive media===*/

/*====invited user list delete button start===*/
.textarea {
  border: 1px solid #d6d6d6;
  width:82%;
  position:relative;
  z-index:1;
}

textarea:focus-visible {
  border: 1px solid #00684d;
  outline: none;
}

textarea:focus-visible {
  border: 1px solid #00684d;
  outline: none;
}

textarea:focus-visible {
  border: 1px solid #00684d;
  outline: none;
} 
// .css-1wcaknn {
//   max-width: 400px;
//   text-align: center;
// }
 
// .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
//   background-color: rgba(0, 0, 0, 0.1) !important;
// }
// .css-dmmspl-MuiFormGroup-root{
//   display:inline!important;
// }
.maintenance_date{
 
  display:flex;
  padding:10px 0px;
  h4{
    font-size:30px;
  }
  .maintenance_mode_to{
    font-size:30px!important;
  
  }
}

.settlement, .pastnum, .paymentReport-dropdown {
  height: 55;
  align-items: center;
  background-color: #ffffff;
}

/*====invited user list delete button end===*/

// .paymentReport .css-1xhypcz-MuiStack-root>.MuiTextField-root{
//    min-width: auto!important;
// }

.margin_submit_button{
  margin-left: -2px !important;
}

.margin_reset_button{
  margin-left: -75px !important;
}



// MUI Data Table Styling
 
// .css-11mde6h-MuiPaper-root {
//   background-color: rgba(0, 0, 0, 0) !important;
//   box-shadow: none !important;
// }


// .css-ubpweo-MuiPaper-root-MuiPopover-paper {
//    width: 245px!important;
//   right: 0;
// }
// .css-1nrlq1o-MuiFormControl-root {
//   display: inline-flex !important;
//   -ms-flex-direction: column !important;
//   flex-direction: column !important;
//   position: relative !important;
//   min-width: 0 !important;
//   border: 0 !important;
//   vertical-align: top !important;
//   // padding: 20px !important;
//   margin: 10px 15px !important;
// }

// MUI Data Table Styling

// Side Navigation Menu

.header-side-navbar .MuiPaper-elevation16 {
  width: 300px;
}
.header-side-navbar .MuiPaper-elevation16 span {
  font-size: 15px !important;
  color: #000;
}
.header-side-navbar div a {
  text-decoration: none;
}
.side-nav-bar-list {
  cursor: pointer;
}
.side-nav-bar-list:hover {
  background-color: #ededed;
}
.side-navbar-list-items li div {
  text-decoration: none !important;
  padding-left: 20px !important;
}
.side-navbar-list-items li:hover {
  background-color: #ededed;
}
.side-nav-bar-close-section {
  height: 64px;
  align-items: center;
  background-color: #00684d;
  color: #ddd;
  font-size: 50px;
}
.side-nav-bar-close-section svg {
  cursor: pointer;
}
// Side Navigation Menu End
// Account journal CSS Starts
.text-center {
  text-align: center;
}

.justify-center {
  display: flex;
  justify-content: center;
}
.table_right {
  float: right;
  margin-top: 30px;
}
.flter_icon {
  color: #757575;
  margin-right: 20px;
  cursor: pointer;
}
.text_center {
  text-align: center;
}
.verticle-middle {
  top: 50%;
  position: relative;
  transform: translate(0, -50%);
}
.detail-border {
  border: 1px solid #ccc;
}
.world-break{
  word-break: break-all;
}

.text-center{
  text-align: center;
}

.box-size{
  width: auto !important;
}

.journal-heading-size{
  width: 1216px !important;
}
.paymentReport {
  padding-left: 2;
  padding-right: 2;

  .date-picker-default {
    background-color: white;
    margin-right: 10px !important;
  }

  .PaymentReport-datepicker {
    display: flex !important;
    align-items: baseline;
    margin-top: 8px !important;
  }

  @media screen and (max-width: 880px) {
    .paymentReports-product-table {
      display: block !important;
      overflow-x: scroll;
    }
  }

  .paymentReport-dropdown {
    height: 55;
    align-items: center;
    background-color: #ffffff;
  }

  .paymentReport-table-header {
    margin-top: 1;
    background-color: #ffffff;
  }
}


.shipment-confirm-order {
  div:first-child div:first-child {
    gap: 0;
    
  }
}

.scroll-auto{
  width: 1216px !important;
  overflow-x: auto !important;
}

@media screen and (max-width:992px) {
.journaldata_table_scroll{
  overflow-x: auto;
}
.shipment-confirm-order > div:first-child > div:first-child {
  justify-content: space-between;
}
.shipment-confirm-order {
  width: 100%;
}
}
.sales_history_negative_quantity_red{
  color: red !important;
  border: hidden !important;
  text-align:right;

}

.sales_history_negative_quantity_black{
  color: black !important;
  border: hidden !important;
  text-align:right;

}
// .oms-Shipment-table {
//   .css-19gb7ar-MuiFormLabel-root-MuiInputLabel-root {
//     line-height: 15px !important;
//     margin-top: -5px;
//     font-size: 12px !important;
//   }
  // .css-19i4bzq-MuiFormLabel-root-MuiInputLabel-root {
  //   top: -10px;
  // }
  // .css-11lq3yg-MuiGrid-root {
  //   justify-content: space-between;
  // }
  .text-validation {
    color: $danger;
    margin-top: 2px;
    font-size: 12px;
    margin-left: 2px;
  }
  // .css-1m0zcpu-MuiButtonBase-root-MuiButton-root {
  //   cursor: default;
  // }
  //mobile view
  @media screen and (max-width: 600px) {
    .margin-top{
      margin-top: 0px !important;
    }
  }
  @media screen and (max-width: 700px) {
    .shipment-order-summary-detail {
      text-align: center;
      margin-right: 0px;
      margin-bottom: 10px;
    }
    // .css-1ex1afd-MuiTableCell-root {
    //   vertical-align: middle !important;
    // }
    .shipment-order-summary-detail div:nth-child(2) > div {
      justify-content: center;
      gap: 10px;
      div {
        width: 100%;
        justify-content: flex-start !important;
      }
    }
    .shipment-order-shipment {
      text-align: center;
    }
    // .css-1gtfl7l {
    //   width: 90px;
    // }
    .shipment-order-shipment div:nth-child(2) > div {
      justify-content: center;
      gap: 20px;
      div {
        width: 100%;
        justify-content: flex-start !important;
      }
    }
    .shipment-button-group {
      display: flex;
      gap: 20px;
    }
    .shipment-product-table {
      display: block;
      overflow-x: scroll;
    }
    .shipment-confirm-order {
      div:first-child div:first-child {
        gap: 0;
        text-align: center;
      }
    }
    .shipment-confirm-detail {
      width: 100%;
      div:nth-child(3) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        p {
          width: auto;
        }
      }
    }
  }
  // Tablet View
  @media screen and (max-width: 900px) {
    .shipment-order-summary-detail {
      width: 100%;
      text-align: center;
      margin-right: 0px;
      margin-bottom: 10px;
    }
    // .css-1ex1afd-MuiTableCell-root {
    //   vertical-align: middle !important;
    // }
    .shipment-order-summary-detail div:nth-child(2) > div {
      justify-content: space-around;
      text-align: left;
    }
    .shipment-order-shipment {
      width: 100%;
      text-align: center;
    }
    .shipment-product-detail {
      td:nth-child(2) > div {
        flex-wrap: wrap;
      }
    }

    .shipment-order-shipment div:nth-child(2) > div {
      justify-content: space-around;
      text-align: left;
    }
    .shipment-button-group {
      display: flex;
      gap: 20px;
    }
    // .shipment-product-table {
    //   display: block;
    //   overflow-x: scroll;
    // }
    .shipment-confirm-order > div:first-child > div:first-child {
      justify-content: space-between;
    }
    .shipment-confirm-order {
      width: 100%;
    }
    .shipment-confirm-product {
      width: 100%;
      margin-right: 0px;
    }

    .shipment-confirm-detail > div:nth-child(3) {
      flex-wrap: wrap;
      gap: 10px;
    }

    .confirm-shipment-button {
      justify-content: flex-end;
      button:first-child {
        margin-right: 10px;
      }
    }
  }

  .confirm-shipment-button {
    justify-content: flex-end;
    button:first-child {
      margin-right: 10px;
    }
  }
  // .css-1ex1afd-MuiTableCell-root {
  //   vertical-align: middle !important;
  // }
  .shipment-product-table {
    thead {
      background-color: #f0f2f2;
      th {
        min-width: 35px;
      }
      th:nth-child(2) {
        // text-align: center;
      }
    }
  }

.file_upload_replace_btn{
  margin:10px!important;
}

.text_wrap_data {
  text-overflow: ellipsis !important;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: break-all !important;
  padding-right: 36px;
}
.confirm_card,
.ship_card {
  border: 2px solid #f0f2f2;
  padding: 20px;
  min-height: 241px;
  p {
    width: 100%;
    padding: 4px 0px;
  }
  h4 {
    font-size: 20px;
    font-weight: 600;
  }
  .flex_item {
    display:flex;
  }
}
.refund_order_text_color {
  color: red;
}
.flex-end {
 display: flex;
 justify-content: flex-end;
}
.fw {
  font-weight: bold !important;
}

.textarea {
  width: 96% !important;
  height: 50% !important;
  border: 1px solid #8a7e7e;
}
.link_color {
  text-decoration: none;
  border: none !important;
  color: #00684d !important;
}
// .sales_history_first_table_cell_small_width{  
//   table td:nth-child(3) div{
//   width:auto!important
//   }
//   table th:nth-child(3) div{
//   width:auto!important
//   }
//   table td:last-child div{
//     width:auto!important
//   }
//   table th:last-child div{
//       width:auto!important;
//   }
// }
.appMinHeight{
  min-height: 83vh;
  @media screen and (min-height: 700px) {
    min-height: 85vh;
  }
  @media screen and (min-height: 800px) {
    min-height: 87vh;
  } 
   @media screen and (min-height: 900px) {
    min-height: 88vh;
  }
  @media screen and (min-height: 1000px) {
    min-height: 89vh;
  }
  @media screen and (min-height: 1100px) {
    min-height: 90vh;
  }
  @media screen and (min-height: 1200px) {
    min-height: 91vh;
  }
}
.edit_mainatinance_clear_btn{
height: 25px;
margin-top: 25px !important;

 @media screen and (max-width: 599px) {
  margin-bottom: 25px !important;
  margin-top: 0px !important;
 }
}

.model_content{
  width: auto;
  text-align: center;
}
 
// Account journal CSS Ends

// Encryption/Decryption tool CSS
.encryption_decryption_box{
  text-align:center;
  .encryption_decryption_btn{
    margin:0px 10px;
    text-transform:capitalize;
  }
}
.download_btn{
  text-transform:capitalize!important
}
.btn_box{
    margin: 10px 0px!important;
   display: flex;
   justify-content:space-between;
   width:100%;
}
.filter_icon{
  background-color:rgb(0, 72, 53);
  color:#fff;
  margin-top: 17px;
  margin-right: 10px;
  cursor:pointer;
}

.upload-btn-grid {
  display: flex;
  justify-content: center;
  margin-top: 20px !important;
}

.filter_bg{
 background:#dddddd63;
  padding:10px 0px;
}
.sortingIconPosition{
  color: #1c1c1c;
  font-size: 23px !important;
}
.swapVertIcon{
  color: #c1c1c1 !important;
}
.rms_img_box{
  height: 50vh;
  position: sticky;
  background-color: #EFFBF8;
  top: 45%;
  @media screen and (max-width: 1199.9px) {
      display: none !important;   
  }
}
.rms_img{
  position: absolute;
  max-height: 40vh;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);

}
.rms_rules_box{
  position: absolute;
  bottom: 3%;
  left: 3%;
}
.rms_rules_headlines{
  padding-bottom: 20px;
  font-size: larger !important;
}

.rms_rules_notes{
  padding-bottom: 5px;
  font-size: small !important;
}
.role_style{
  font-size: large !important;
  font-weight: 700 !important;


}
.css-11mde6h-MuiPaper-root{
  box-shadow: none !important;
}
.padding-0{
  padding: 0px !important;
}
.rms_instruction_icon{
  font-size: 38px !important;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
  @media screen and (min-width: 1200px) {
    display: none !important;
  }
}
.creatUser_img{
  position: absolute;
  max-height: 40vh;
  top: -4%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.scrollbar{
  max-height: 400px;
  overflow-y: scroll;
}
.createUserRoleTable{
  margin: 0 !important;
  th > div {
    font-size: 13px;
  }
  td > div {
    font-size: 13px;
  }
}
.AdministrativeSiteBox{
  border: 1px solid #ACACAC;
  margin-top: 15px;
}
.administrativeSiteTitle{
  background-color: #EFFBF8;
  height: 68px;
  border-block-end: 1px solid #ACACAC;
  display: flex;
  align-items: center;
  font-size: larger !important;
  padding-left: 10px;
}
.SellerPermissionTitle{
  @media screen and (max-width: 600px) {
    height: 100px;
  }
}
.sellerPermissionTitle{
  font-size: large !important;
}
.rms_delete_icon{
  display: flex;
  align-items: center;
  justify-content: end;
}

.sellerPermissionBox{
  display: flex;
  align-items: center;
}
.rms_search{
  display: flex;
  justify-content: end;
}
.header-margin-bottom{
  // margin-bottom: 20px !important;
}
.msg_position{
  top: 0% !important;
}
.admin_user_role_box{
  height: 40vh;
  position: sticky;
  background-color: #EFFBF8;
  top: 45%;
  @media screen and (max-width: 1199px) {
      display: none !important;   
  }
}
.rms_save_btn{
  display: flex !important;
  padding: 20px 0px !important;
  text-transform: capitalize !important;
  margin-right: 4px !important;
}
.receivingEmailPermissionTable{
  table td:first-child div {
    width: 30px !important;
}
table th:first-child div {
    width: 30px !important;
}
  table td:nth-child(2) div {
    width: 30px !important;
}
table th:nth-child(2) div {
    width: 30px !important;
}
table td:last-child div{
  width:auto!important;
 }
  table th:last-child div{
    width:auto!important;
  }
}
.rms_table{
  table td:nth-child(2) div {
    width: 250px !important;
}
table th:nth-child(2) div {
    width: 250px !important;
}
table td:nth-child(3) div {
  width:80px !important;
}
table th:nth-child(3) div {
  width: 80px !important;
}
}
.seller_permission_icon{
  margin: 0px 6px !important;
  width: 23px !important;
  background-color: #fff !important;
  color: #008E26 !important;
  border:2px solid #008E26 !important;
  box-shadow: none !important;
}
.seller_permission_icon_selected{
  margin: 0px 6px !important;
  width: 23px !important;
  background-color: #008E26 !important;
  color: #fff !important;
  border:2px solid #fff !important;
  box-shadow: none !important;
}
.seller_permission_header_box .Mui-disabled{
  color: rgba(0, 0, 0, 0.25) !important;
  border:2px solid rgba(0, 0, 0, 0.25) !important;
  box-shadow: none !important;
  background-color: #fff !important;
}
.auto_complete .Mui-disabled {
  border:none !important;
}
.seller_permission_header_box{
  display:flex;
  justify-content: end;
}
.all_store{
  display: flex;
  justify-content: center;
  margin: 12px !important;
}
.payment_history_download_btn{
  float:right ;
  text-align:right;
}
.store_invite_btn{
  padding-top: 16px!important;
  padding-bottom: 16px !important;
  size: small !important;
  float: right;
}
.unregistered_refresh{
  display: flex;
  // justify-content: flex-end;
  align-items: center;
  gap: 10px;
  font-weight: 600;
}
.unregistered_refresh_btn{
  size: small !important;
  float: right;
}
.notification_msg {
  background-color: #F29810 !important;
  height: 100% !important;
  width: 100%;
  font-size: 14px !important;
}
.word_wrap {
  word-wrap: break-word; /* Allows words to be broken at arbitrary points */
  white-space: normal;   /* Allows text to wrap to new lines */
}
.msg_position{
  top: 0% !important;
  background-color: #F29810 !important;
  width: 100%;
  position: absolute;
  font-size: 14px !important;
  // top: 10%;
}
.system_update{
  margin-top:100px;
  .system_update_btn{
    margin-top:100px;
  }
}
.system_img{
     max-width:600px;
}
.internet_connection_snackbar .MuiSnackbar-root {
  position: fixed ;
  top: 8%;
}
.category_btn{
  padding-top: 10px!important;
  padding-bottom: 10px !important;
  size: small !important;
  float: right;
  min-width: 145px !important;
}
.category-update{
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 30px;
}
.notification-modal-description{
  margin-top: 2px !important;
  padding: 10px;
  white-space: pre-wrap;
}
.system-update-read-more{
  cursor: pointer !important;
  font-size: 12px !important;
  color: #00684D !important;
  font:inherit !important;
}
.clampedText {
  overflow: hidden;
  line-height: 1.8; /* Adjust based on your font size */
  white-space: pre-line; /* Keeps the line breaks (\n) */
  max-height: 100px;
}
.css-ao8rxk{
  box-shadow: none !important;
 }
